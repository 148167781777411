<template>
  <Transition name="offer-fade">
    <template v-if="highlightedOffer !== false">
      <div class="offer-highlight" :class="`${isRecommended} ${offerClass}`" :data-offer-id="highlightedOffer.id">
        <div class="offer-highlight__header">
          <div class="offer-highlight__special offer-special">
            {{ i18n.highLightedOffer.specialDeal }}
          </div>
          <div class="offer-highlight__title t-h2">
            {{ highlightedOffer.title }}
          </div>

          <!-- promotional_code -->
          <div v-if="highlightedOffer.promotional_code" class="offer-highlight__copy-to-clipboard t-label">
            <span> {{ i18n.highLightedOffer.copyCode }}: </span>
            <button
              type="button"
              :data-promocode="highlightedOffer.promotional_code"
              class="code js-copy-to-clipboard offer-highlight__promocode show-icon"
              :data-translations="i18n.highLightedOffer.textCopied"
              @click="handleCopyToClipboardClick"
            >
              {{ highlightedOffer.promotional_code }}
            </button>
          </div>
        </div>
        <div
          v-if="offerVisualization === 'with-description' && !currentDevice.isMobile"
          class="offer-highlight__description"
          v-html="highlightedOffer.description"
        ></div>
        <div v-if="offerVisualization === 'with-highlights'" class="offer-highlight__highlights o-highlights">
          <div
            v-for="(oHighlight, index) in highlightedOffer.offer_highlights"
            :key="index"
            class="o-highlights__highlight"
          >
            <span class="o-highlights__icon s s-xl" :class="'s-' + oHighlight.icon"></span>
            <div class="o-highlights__title">
              {{ oHighlight.title }}
            </div>
            <p class="o-highlights__subtitle t-p">
              {{ oHighlight.subtitle }}
            </p>
          </div>
        </div>
        <div class="offer-highlight__badges">
          <div class="offer-highlight__badge offer-badge">
            <p v-if="stayFromIsPast">
              {{ t(i18n.highLightedOffer.travelTo, { stay_to: stayToFormatted }) }}
            </p>
            <p v-else>
              {{
                t(i18n.highLightedOffer.travelFromTo, {
                  stay_from: stayFromFormatted,
                  stay_to: stayToFormatted,
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </Transition>
</template>

<script setup>
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import { computed, inject, ref } from 'vue'

import { currentDevice } from '../../../../../core/utils/currentDevice'
import { handleCopyToClipboardClick } from '../../../../../utils/copy_to_clipboard'
import { replaceTranslationKeys as t } from '../../../../../utils/replaceTranslationKeys'
import { useHotelsInfoStore } from '../../../blocks/HotelsInfo/store/hotels_info'
import { loadStyles } from '../../../mixins/LoadStyles/LoadStyles'

/**
 * Styles
 */
const COMPONENT_NAME = 'HighlightedOffer'
loadStyles({ name: COMPONENT_NAME, folder: 'components/HotelsInfo' })

/**
 * Inject
 */
const i18n = inject('i18n')

/**
 * Datas
 */
const dateFormat = ref(document.documentElement.dataset.dateFormat)
const today = dayjs()
const offerVisualizations = ['with-highlights', 'with-description', '']

/**
 * Store
 */
const hotelsInfoStore = useHotelsInfoStore()

const { highlightedOffer } = storeToRefs(hotelsInfoStore)

const stayFrom = dayjs(highlightedOffer.value.stay_from)
const stayFromIsPast = stayFrom.isBefore(today, 'day') || stayFrom.isSame(today, 'day')

/**
 * Methods
 */
const formatDate = date => {
  if (dateFormat.value && date) {
    return dayjs(date).format(dateFormat.value)
  }
}

/**
 * Computed
 */
const offerVisualization = computed(() => {
  let visualization = offerVisualizations[highlightedOffer.value.offer_highlights_visualization - 1]

  if (visualization === 'with-highlights' && highlightedOffer.value.offer_highlights.length < 2) {
    visualization = ''
  }

  if (visualization === 'with-description' && (highlightedOffer.value.description === '' || currentDevice.isMobile)) {
    visualization = ''
  }

  return visualization
})

const offerClass = computed(() => {
  return offerVisualization.value ? `offer-highlight--${offerVisualization.value}` : ''
})

const isRecommended = computed(() => (highlightedOffer.value.recommended ? 'offer-highlight--recommended' : ''))

const stayFromFormatted = computed(() => formatDate(highlightedOffer.value.stay_from))
const stayToFormatted = computed(() => formatDate(highlightedOffer.value.stay_to))
</script>
