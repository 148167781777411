<template>
  <div class="dropdown" :class="[detailsClass, { active: isOpen }]">
    <div class="dropdown__summary" :class="[summaryClass]" @click="() => !hasHeader && toggleDropdown()">
      <template v-if="!hasHeader">
        <span class="dropdown__title">{{ props.title }}</span>
        <span class="dropdown__subtitle">{{ props.subtitle }}</span>
      </template>
      <slot v-else name="header"></slot>
      <span class="dropdown__icon s s-arrow-down-fat" @click="() => hasHeader && toggleDropdown()"></span>
    </div>
    <div ref="content" class="dropdown__content">
      <div :class="contentClass">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, useSlots } from 'vue'

import { loadStyles } from '../../mixins/LoadStyles/LoadStyles'

/**
 * Styles
 */
const COMPONENT_NAME = 'Dropdown'
loadStyles({ name: COMPONENT_NAME })

const isOpen = ref(true)
const content = ref(null)

/**
 * Props
 */
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  hideSubtitle: {
    type: Boolean,
    default: false,
  },
  detailsClass: {
    type: String,
    default: '',
  },
  summaryClass: {
    type: String,
    default: '',
  },
  contentClass: {
    type: String,
    default: '',
  },
})

/**
 * Slots
 */
const slots = useSlots()

const hasHeader = computed(() => {
  return slots.header
})

/**
 * Methods
 */

const resizeContent = () => {
  if (isOpen.value) {
    const height = content.value.scrollHeight
    content.value.style.maxHeight = `${height}px`
  } else {
    content.value.style.maxHeight = '0px'
    content.value.style.padding = '0px'
  }
}

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
  resizeContent()
}

/**
 * Lifecycle
 */
onMounted(() => {
  content.value.addEventListener('transitionend', () => {
    if (isOpen.value) {
      resizeContent()
    }
  })
})
</script>
