<template>
  <div class="hotel-list">
    <div class="hotel-list__cards">
      <TransitionGroup name="fade">
        <HotelCard v-for="hotel in hotelsInfoStore.hotelsPaginated" :key="hotel.id" :hotel="hotel" />
      </TransitionGroup>
    </div>

    <ListPagination />
  </div>
</template>

<script setup>
import { useHotelsInfoStore } from '../../../blocks/HotelsInfo/store/hotels_info'
import { loadStyles } from '../../../mixins/LoadStyles/LoadStyles'
import HotelCard from '../HotelCard/HotelCard'
import ListPagination from '../ListPagination/ListPagination'

/**
 * Props
 */
const props = defineProps({
  offerInfo: {
    type: Object,
    default: () => ({}),
  },
})

/**
 * Styles
 */
const COMPONENT_NAME = 'HotelList'
loadStyles({ name: COMPONENT_NAME, folder: 'components/HotelsInfo' })

/**
 * Store
 */
const hotelsInfoStore = useHotelsInfoStore()
</script>
