<template>
  <component :is="html" :class="`t-${html} ${align}`">{{ text }}</component>
</template>
<script setup>
import { loadStyles } from '../../mixins/LoadStyles/LoadStyles'

/**
 * Styles
 */
const COMPONENT_NAME = 'BlockTitle'
loadStyles({ name: COMPONENT_NAME })

/**
 * Props
 */
const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  html: {
    type: String,
    default: 'h2',
    validator(value) {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'].includes(value)
    },
  },
  align: {
    type: String,
    default: 'text-left',
  },
})
</script>
