// Util methods
const segmentUtils = {
  segment: '',

  /**
   * Sets the segment value.
   * @param {string} newSegment - The new segment to set.
   */
  set(newSegment) {
    this.segment = newSegment
  },

  /**
   * Gets the segment value.
   * @returns {string} The current segment.
   */
  get() {
    return this.segment
  },

  /**
   * Checks if a segment is set.
   * @returns {boolean} True if a segment is set, false otherwise.
   */
  has() {
    return typeof this.segment === 'string' && this.segment !== ''
  },
}

/**
 * Initializes the segment based on the class names applied to the body element.
 *
 * This function looks for a class starting with `theme-` on the body element. If such a class is found and
 * its segment is one of the allowed segments (e.g., 'masterbrand', 'city', 'heritage', 'beachfront'),
 * the next class in the list is selected and passed to `segmentUtils.set()`. If no valid class is found,
 * it defaults to 'masterbrand'.
 *
 * The solution of selecting the class next to the one containing the word theme- has been chosen because it is a constant structure.
 * It always comes from the back that way.
 * If it were to change, the function would have to be changed.
 *
 * @function initSegment
 * @returns {void} No return value. This function sets the segment via `segmentUtils.set()`.
 */
const initSegment = () => {
  const elementSelector = 'body'

  // Array of allowed segments that the theme segment must match
  const allowedSegments = ['masterbrand', 'city', 'heritage', 'beachfront']

  const block = document.querySelector(elementSelector)

  if (block) {
    // Find the class that starts with "theme-" (representing the theme segment)
    const themeClass = block.className.split(' ').find(cls => cls.startsWith('theme-'))

    if (themeClass) {
      // Extract the segment name from the class (e.g., "theme-masterbrand" -> "masterbrand")
      const themeSegment = themeClass.split('theme-')[1]

      if (allowedSegments.includes(themeSegment)) {
        const classArray = block.className.split(' ')

        // Find the index of the theme class in the array
        const themeIndex = classArray.indexOf(themeClass)

        // Select the class immediately following the theme class
        const nextSegment = themeIndex !== -1 && classArray[themeIndex + 1] ? classArray[themeIndex + 1] : null

        // Set the next segment if it exists, or default to 'masterbrand'
        segmentUtils.set(nextSegment || 'masterbrand')
        return
      }
    }
  }

  // Default to 'masterbrand' if no valid theme segment or next class is found
  segmentUtils.set('masterbrand')
}

export { initSegment, segmentUtils }
