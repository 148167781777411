<template>
  <div class="modal__trigger" @click="openModal">
    <slot name="trigger"></slot>
  </div>
  <div :id="modalId" class="modal js-modal micromodal-slide" aria-hidden="true" :data-type="props.type">
    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
      <div
        class="modal__container js-modal-scrollable-container"
        :class="props.modalClass"
        data-qa="modal--div--div__modal-container"
        role="dialog"
        aria-modal="true"
        :aria-labelledby="`${modalId}-title`"
      >
        <header class="modal__header js-modal-header">
          <slot name="header"></slot>
          <button
            v-if="!hideCloseButton"
            class="btn-close-round"
            aria-label="Close modal"
            data-micromodal-close
            data-qa="modal--div--button__modal-close"
            @click="closeModal"
          ></button>
        </header>
        <main :id="`${modalId}-content`" class="modal__content">
          <slot name="content"></slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup>
import Modal from '../../../../../src/shared/modal/modal.js'

const props = defineProps({
  modalId: {
    type: String,
    required: true,
  },
  hasHeader: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: '',
  },
  modalClass: {
    type: String,
    default: '',
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
})

// const modalId = ref(`modal-${Date.now()}`)

const openModal = () => {
  Modal.show(props.modalId, {
    onShow: () => {
      ScrollLock.on()
    },
    onClose: () => {
      ScrollLock.off()
    },
  })
}

const closeModal = () => {
  Modal.close(props.modalId)
}
</script>

<style scoped>
.modal__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal__trigger {
  cursor: pointer;
}
</style>
