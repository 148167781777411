import { createPinia } from 'pinia'
import { createApp } from 'vue'

import hotelsinfo from '../../../src/vue/src/blocks/HotelsInfo/HotelsInfo.vue'

const BASE_ID_SELECTOR = '#hotels-info-with-filtering-vue'

function initVue() {
  const vueBlock = document.querySelector(BASE_ID_SELECTOR)
  if (!vueBlock) return

  const app = createApp({
    el: BASE_ID_SELECTOR,
    // Root components must be imported in lowercase
    components: {
      hotelsinfo,
    },
  })

  app.use(createPinia())

  app.mount(BASE_ID_SELECTOR)
}

initVue()
