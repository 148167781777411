<template>
  <section class="list-filters">
    <Dropdown
      v-if="(currentDevice.isMobile || currentDevice.isTablet) && sortOptions"
      :title="i18n.orderBy.title"
      :subtitle="i18n.orderBy.options[currentSort.name]"
      details-class="list-filters__filter list-filter"
      summary-class="list-filter__summary"
      content-class="list-filter__content"
    >
      <template #content>
        <div
          v-for="option in sortOptions"
          :key="option.name"
          class="list-filter__option"
          @click="changeSort(option.field, option.direction)"
        >
          <input
            :id="`sort-${option.name}`"
            :value="`${option.field} - ${option.direction}`"
            name="sort"
            type="radio"
            class="list-filter__radio"
            :checked="option.field === sort.field && option.direction === sort.direction"
          />
          <label :for="`option-${option.name}`" class="list-filter__label">
            {{ i18n.orderBy.options[option.name] }}
          </label>
        </div>
      </template>
    </Dropdown>

    <Dropdown
      v-if="props.filters.offers && props.filters.offers.visible"
      :title="filtersComputed.offers.title"
      :subtitle="filtersComputed.offers.legend"
      details-class="list-filters__filter list-filter"
      summary-class="list-filter__summary"
      content-class="list-filter__content"
    >
      <template #content>
        <span class="dropdown__legend t-label">{{ filtersComputed.offers.legend }}</span>
        <div v-for="offer in filtersComputed.offers.options" :key="offer.id" class="list-filter__option">
          <input
            :id="`offer-${offer.id}`"
            v-model="selectedFilters.offers"
            :value="offer.value"
            type="checkbox"
            class="list-filter__checkbox"
            :disabled="!offer.count"
          />
          <label :for="`offer-${offer.id}`" class="list-filter__label" :class="{ disabled: !offer.count }">
            {{ offer.label }} {{ getCounterText(offer.count) }}
          </label>
        </div>
      </template>
    </Dropdown>

    <Dropdown
      :title="filtersComputed.destinations.title"
      :subtitle="filtersComputed.destinations.legend"
      details-class="list-filters__filter list-filter"
      summary-class="list-filter__summary"
      content-class="list-filter__content"
    >
      <template #content>
        <span class="dropdown__legend t-label">{{ filtersComputed.destinations.legend }}</span>
        <template v-for="destination in filtersComputed.destinations.options" :key="destination.id">
          <div
            v-if="!destination.value.children || destination.value.children.length === 0"
            class="list-filter__option"
          >
            <input
              :id="`destination-${destination.id}`"
              v-model="selectedFilters.destinations"
              :value="destination.value"
              type="checkbox"
              class="list-filter__checkbox"
              :disabled="!destination.count"
              @click="handleFilterClick('countries', destination.label)"
            />
            <label
              :for="`destination-${destination.id}`"
              class="list-filter__label"
              :class="{ disabled: !destination.count }"
            >
              {{ destination.label }} {{ getCounterText(destination.count) }}
            </label>
          </div>
          <Dropdown v-else class="list-filter__destination" content-class="list-filter__subdestinations">
            <template #header>
              <div class="list-filter__option">
                <input
                  :id="`destination-${destination.id}`"
                  type="checkbox"
                  class="list-filter__checkbox"
                  :class="getCheckedSubdestinationsClass(destination)"
                  :disabled="!destination.count"
                  @click="e => handleDestinationClick(destination, e)"
                />
                <label
                  :for="`destination-${destination.id}`"
                  class="list-filter__label"
                  :class="{ disabled: !destination.count }"
                >
                  {{ destination.label }} {{ getCounterText(destination.count) }}
                </label>
              </div>
            </template>
            <template #content>
              <div v-for="child in destination.value.children" :key="child.id" class="list-filter__option-container">
                <div class="list-filter__option">
                  <input
                    :id="`destination-${child.id}`"
                    v-model="selectedFilters.destinations"
                    :value="child.value"
                    type="checkbox"
                    class="list-filter__checkbox"
                    :disabled="!child.count"
                    @click="handleFilterClick('countries', child.label)"
                  />
                  <label
                    :for="`destination-${child.id}`"
                    class="list-filter__label"
                    :class="{ disabled: !child.count }"
                  >
                    {{ child.label }} {{ getCounterText(child.count) }}
                  </label>
                </div>
                <div v-if="child.count > 0" class="list-filter__action">
                  <span class="list-filter__only-button" @click="selectOnlyDestinationFilter(child.value)">
                    {{ i18n.filters.only }}
                  </span>
                </div>
              </div>
            </template>
          </Dropdown>
        </template>
      </template>
    </Dropdown>

    <Dropdown
      v-if="props.filters.dates && props.filters.dates.visible"
      :title="filtersComputed.dates.title"
      :subtitle="filtersComputed.dates.legend"
      details-class="list-filters__filter list-filter"
      summary-class="list-filter__summary"
      content-class="list-filter__content"
    >
      <template #content>
        <span class="dropdown__legend t-label">{{ filtersComputed.dates.legend }}</span>
        <div v-for="date in filtersComputed.dates.options" :key="date.id" class="list-filter__option">
          <input
            :id="`date-${date.id}`"
            v-model="selectedFilters.dates"
            :value="date.value"
            type="checkbox"
            class="list-filter__checkbox"
            :disabled="!date.count"
            @click="handleFilterClick('countries', date.label)"
          />
          <label :for="`date-${date.id}`" class="list-filter__label" :class="{ disabled: !date.count }">
            {{ date.label }} {{ getCounterText(date.count) }}
          </label>
        </div>
      </template>
    </Dropdown>

    <Dropdown
      :title="i18n.filters.servicesTitle"
      :subtitle="`${filtersComputed.categories.legend}, ${filtersComputed.overview_features.legend}`"
      details-class="list-filters__filter list-filter"
      summary-class="list-filter__summary"
      content-class="list-filter__content"
    >
      <template #content>
        <span class="dropdown__legend t-label">{{ filtersComputed.categories.legend }}</span>
        <div v-for="category in filtersComputed.categories.options" :key="category.id" class="list-filter__option">
          <input
            :id="`category-${category.id}`"
            v-model="selectedFilters.categories"
            :value="category.value"
            type="checkbox"
            class="list-filter__checkbox"
            :disabled="!category.count"
            @click="handleFilterClick('stars', category.label)"
          />
          <label :for="`category-${category.id}`" class="list-filter__label" :class="{ disabled: !category.count }">
            <span :class="`rating-stars r${category.label}`"></span>
            {{ getCounterText(category.count) }}
          </label>
        </div>

        <span class="mt-2 dropdown__legend t-label">{{ filtersComputed.overview_features.legend }}</span>
        <div
          v-for="overview_feature in filtersComputed.overview_features.options"
          :key="overview_feature.id"
          class="list-filter__option"
        >
          <input
            :id="`overview-feature-${overview_feature.id}`"
            v-model="selectedFilters.overview_features"
            :value="overview_feature.value"
            type="checkbox"
            class="list-filter__checkbox"
            :disabled="!overview_feature.count"
            @click="handleFilterClick('services', overview_feature.label)"
          />
          <label
            :for="`overview-feature-${overview_feature.id}`"
            class="list-filter__label"
            :class="{ disabled: !overview_feature.count }"
          >
            {{ overview_feature.label }}
            {{ getCounterText(overview_feature.count) }}
            <tippy
              v-if="overview_feature.value.tooltip"
              :content="overview_feature.value.tooltip"
              theme="light"
              trigger="focus mouseenter click"
              placement="right"
            >
              <span class="list-filter__tooltip s s-alert-info"></span>
            </tippy>
          </label>
        </div>
      </template>
    </Dropdown>

    <Dropdown
      :title="filtersComputed.specialties.title"
      :subtitle="filtersComputed.specialties.legend"
      details-class="list-filters__filter list-filter"
      summary-class="list-filter__summary"
      content-class="list-filter__content"
    >
      <template #content>
        <span class="dropdown__legend t-label">{{ filtersComputed.specialties.legend }}</span>
        <div v-for="specialty in filtersComputed.specialties.options" :key="specialty.id" class="list-filter__option">
          <input
            :id="`specialty-${specialty.id}`"
            v-model="selectedFilters.specialties"
            :value="specialty.value"
            type="checkbox"
            class="list-filter__checkbox"
            :disabled="!specialty.count"
            @click="handleFilterClick('specialities', specialty.label)"
          />
          <label :for="`specialty-${specialty.id}`" class="list-filter__label" :class="{ disabled: !specialty.count }">
            {{ specialty.label }} {{ getCounterText(specialty.count) }}
          </label>
        </div>
      </template>
    </Dropdown>
  </section>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, watch } from 'vue'
import { Tippy } from 'vue-tippy'

import { currentDevice } from '../../../../../core/utils/currentDevice'
import { useHotelsInfoStore } from '../../../blocks/HotelsInfo/store/hotels_info'
import { loadStyles } from '../../../mixins/LoadStyles/LoadStyles'
import Dropdown from '../../Dropdown/Dropdown'
import { emitUtagEvent } from '../HotelsInfo.analytics'
import sortOptions from '../ListSort/sortOptions'

/**
 * Styles
 */
const COMPONENT_NAME = 'ListFilters'
loadStyles({ name: COMPONENT_NAME, folder: 'components/HotelsInfo' })

/**
 * Inject
 */
const i18n = inject('i18n')

/**
 * Props
 */
const props = defineProps({
  filters: {
    type: Object,
    required: true,
    default: () => ({}),
  },
})

/**
 * Store
 */
const hotelsInfoStore = useHotelsInfoStore()
const {
  filters: selectedFilters,
  hotelsFiltered: hotels,
  hotelsFilteredToOffers,
  hotelsFilteredToDestinations,
} = storeToRefs(hotelsInfoStore)

if (props.filters.offers && props.filters.offers.visible) {
  hotelsInfoStore.setOffers(props.filters.offers.options.map(offer => offer.value))
}

const { sort } = storeToRefs(hotelsInfoStore)
hotelsInfoStore.setSort(sortOptions[0].field, sortOptions[0].direction)

/**
 * Computed
 */
const filtersComputed = computed(() => {
  const filtersAux = props.filters

  // Filter offers
  const hotelIds = hotelsFilteredToOffers.value.reduce((acc, hotel) => {
    acc.push(hotel.id)
    return acc
  }, [])
  filtersAux.offers?.options?.map(offer => {
    offer.count = offer.value.hotel_ids.filter(id => hotelIds.includes(id)).length
    return offer
  })

  // Filter destinations
  filtersAux.destinations.options.map(destination => {
    destination.count = hotelsFilteredToDestinations.value.filter(hotel =>
      destination.value.hotels.includes(hotel.id)
    ).length

    destination.value.children.map(child => {
      child.count = hotelsFilteredToDestinations.value.filter(hotel => child.value.hotels.includes(hotel.id)).length
      return child
    })

    return destination
  })

  // Filter dates
  filtersAux.dates?.options?.map(date => {
    date.count = hotels.value.filter(hotel => date.value.hotels.includes(hotel.id)).length
    return date
  })

  // Filter categories
  filtersAux.categories.options.map(category => {
    category.count = hotels.value.filter(hotel => hotel.hotel_category === category.id).length
    return category
  })

  // Filter overview features
  filtersAux.overview_features.options.map(overview_feature => {
    overview_feature.count = hotels.value.filter(hotel =>
      hotel.overview_features_ids?.includes(overview_feature.id)
    ).length
    return overview_feature
  })

  // Filter specialties
  filtersAux.specialties.options.map(specialty => {
    specialty.count = hotels.value.filter(hotel => hotel.hotel_specialty_ids.includes(specialty.id)).length
    return specialty
  })

  return filtersAux
})

const currentSort = computed(() => {
  return sortOptions.find(option => option.field === sort.value.field && option.direction === sort.value.direction)
})

/**
 * Methods
 */
const updateFiltersWithParams = () => {
  // Check url params and update filters
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('filter_offers')) {
    const offerIds = urlParams.get('filter_offers').split(',').map(Number)
    const offers = offerIds.map(offer => {
      const option = props.filters.offers.options.find(option => option.id === offer)
      return option.value
    })
    hotelsInfoStore.updateFilters('offers', offers)
  }
  if (urlParams.has('filter_destinations')) {
    const destinationIds = urlParams.get('filter_destinations').split(',').map(Number)
    const destinations = destinationIds
      .map(destination => {
        let option = props.filters.destinations.options.find(option => option.id === destination)

        if (!option) {
          props.filters.destinations.options.some(dest => {
            option = dest.value.children.find(child => child.id === destination)
            return option
          })
        }

        if (!option) {
          return false
        }

        if (option.value.children && option.value.children.length > 0) {
          return option.value.children.map(child => child.value)
        }

        return option.value
      })
      .filter(Boolean)
      .flat()

    hotelsInfoStore.updateFilters('destinations', destinations)
  }
  if (urlParams.has('filter_categories')) {
    const categoryIds = urlParams.get('filter_categories').split(',').map(String)
    const categories = categoryIds.map(category => {
      const option = props.filters.categories.options.find(option => option.id === category)
      return option.value
    })
    hotelsInfoStore.updateFilters('categories', categories)
  }
  if (urlParams.has('filter_specialties')) {
    const specialtyIds = urlParams.get('filter_specialties').split(',').map(Number)
    const specialties = specialtyIds.map(specialty => {
      const option = props.filters.specialties.options.find(option => option.id === specialty)
      return option.value
    })
    hotelsInfoStore.updateFilters('specialties', specialties)
  }
}

const getCounterText = count => {
  let text = i18n.value.filters.hotelsCounter.one
  if (count !== 1) {
    text = i18n.value.filters.hotelsCounter.other
  }
  return `(${text.replace('%{count}', count)})`
}

const changeSort = (field, direction) => {
  hotelsInfoStore.setSort(field, direction)
}

const getCheckedSubdestinationsClass = destination => {
  if (!selectedFilters.value.destinations) {
    return false
  }

  if (destination.value.children.every(child => selectedFilters.value.destinations.includes(child.value))) {
    return 'list-filter__checkbox--checked'
  }
  if (destination.value.children.some(child => selectedFilters.value.destinations.includes(child.value))) {
    return 'list-filter__checkbox--partial'
  }
  return false
}

const handleFilterClick = (act, label) => {
  emitUtagEvent({
    event_name: 'filters',
    event_cat: 'filters destinations',
    event_act: act,
    event_lbl: label,
    event_purpose: 'inspiration',
    event_structure: 'body',
  })
}

const handleDestinationClick = (destination, event) => {
  // Avoid input being checked automatically
  event && event.preventDefault()

  if (!getCheckedSubdestinationsClass(destination)) {
    hotelsInfoStore.updateFilters(
      'destinations',
      destination.value.children.map(child => child.value)
    )
  } else {
    destination.value.children.forEach(child => {
      hotelsInfoStore.removeFilters('destinations', child.value)
    })
  }

  handleFilterClick('countries', destination.label)
}

const selectOnlyDestinationFilter = value => {
  hotelsInfoStore.replaceFilters('destinations', [value])
}

/**
 * Lifecycle
 */
onMounted(() => {
  updateFiltersWithParams()
})

watch(
  () => props.filters,
  () => {
    updateFiltersWithParams()
  }
)
</script>
