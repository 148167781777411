<template>
  <div class="list-sort">
    <template v-if="currentDevice.isDesktop">
      <p class="list-sort__title">{{ i18n.orderBy.title }}:</p>
      <div class="list-sort__dropdown" :class="tippyState">
        <tippy
          allow-html
          interactive
          trigger="click"
          theme="light"
          placement="bottom"
          @show="tippyState = 'list-sort__dropdown--active'"
          @hide="tippyState = ''"
        >
          <span id="sort-trigger" class="list-sort__dropdown-trigger" aria-controls="sort-options">
            {{ i18n.orderBy.options[currentSort.name] }}
          </span>

          <template #content="{ hide }">
            <ul id="sort-options" class="list-sort__dropdown-list" aria-labelledby="sort-trigger">
              <li
                v-for="option in sortOptions"
                :key="option.name"
                class="list-sort__dropdown-item"
                @click="
                  () => {
                    hide()
                    changeSort(option.field, option.direction)
                  }
                "
              >
                {{ i18n.orderBy.options[option.name] }}
              </li>
            </ul>
          </template>
        </tippy>
      </div>
    </template>
    <template v-else-if="(currentDevice.isTablet || currentDevice.isMobile) && !removeFilters && filters !== null">
      <Modal modal-id="list-sort" modal-class="list-sort__modal full-page">
        <template #trigger>
          <p class="list-sort__title">
            <span class="s s-filter mr-2"></span>
            {{ i18n.orderBy.filterBy }}
          </p>
        </template>
        <template #header>
          <p class="list-sort__modal-title">{{ i18n.orderBy.modalTitle }}</p>
        </template>
        <template #content>
          <ListFilters :filters="filters" />
        </template>
      </Modal>
    </template>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { computed, inject, ref } from 'vue'
import { Tippy } from 'vue-tippy'

import { currentDevice } from '../../../../../core/utils/currentDevice'
import { useHotelsInfoStore } from '../../../blocks/HotelsInfo/store/hotels_info'
import { loadStyles } from '../../../mixins/LoadStyles/LoadStyles'
import Modal from '../../Modal/Modal'
import { emitUtagEvent } from '../HotelsInfo.analytics'
import ListFilters from '../ListFilters/ListFilters'

import sortOptions from './sortOptions'

/**
 * Styles
 */
const COMPONENT_NAME = 'ListSort'
loadStyles({ name: COMPONENT_NAME, folder: 'components/HotelsInfo' })

/**
 * Inject
 */
const i18n = inject('i18n')

/**
 * Store
 */
const hotelsInfoStore = useHotelsInfoStore()
const { sort } = storeToRefs(hotelsInfoStore)
hotelsInfoStore.setSort(sortOptions[0].field, sortOptions[0].direction)

/**
 * Props
 */
const props = defineProps({
  filters: {
    type: Object,
    default: () => ({}),
  },
  removeFilters: {
    type: Boolean,
    default: false,
  },
})

/**
 * Data
 */
const tippyState = ref('')

/**
 * Computed
 */
const currentSort = computed(() => {
  return sortOptions.find(option => option.field === sort.value.field && option.direction === sort.value.direction)
})

/**
 * Methods
 */
const changeSort = (field, direction) => {
  hotelsInfoStore.setSort(field, direction)
  emitUtagEvent({
    event_name: 'sort_by',
    event_cat: 'destinations',
    event_act: 'sort by',
    event_lbl: field,
    event_purpose: 'inspiration',
    event_structure: 'body',
  })
}
</script>
