import { sendUtagEvent } from '../../../../core/analytics/utag_events'
import { normalizeString } from '../../../../utils/utilities'

export function emitUtagEvent({
  event_name = '',
  event_cat,
  event_act,
  event_lbl = '',
  event_purpose,
  event_structure = 'body',
}) {
  sendUtagEvent({
    data: {
      event_name,
      event_cat: normalizeString(event_cat),
      event_act: normalizeString(event_act),
      event_lbl: normalizeString(event_lbl),
      event_structure,
      event_purpose,
    },
  })
}
