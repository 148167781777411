<template>
  <div class="list-tabs">
    <template v-if="currentDevice.isDesktop">
      <span
        v-for="tab in tabs"
        :key="tab.key"
        class="list-tabs__item"
        :class="{ active: activeTab === tab.key }"
        @click="setActiveTab(tab.key)"
      >
        {{ tab.text }}
      </span>
    </template>
    <template v-if="currentDevice.isTablet || currentDevice.isMobile">
      <Modal modal-id="list-tabs" modal-class="list-tabs__modal full-page">
        <template #trigger>
          <span class="list-tabs__map">
            <span class="s s-pin mr-2"></span>
            {{ i18n.tabs.seeOnMap }}
          </span>
        </template>
        <template #header>
          <p class="list-tabs__modal-title">{{ i18n.tabs.chooseHotelOnMap }}</p>
        </template>
        <template #content>
          <HotelsMap />
        </template>
      </Modal>
    </template>
  </div>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { inject } from 'vue'

import { currentDevice } from '../../../../../core/utils/currentDevice'
import { useHotelsInfoStore } from '../../../blocks/HotelsInfo/store/hotels_info'
import { loadStyles } from '../../../mixins/LoadStyles/LoadStyles'
import Modal from '../../Modal/Modal'
import { emitUtagEvent } from '../HotelsInfo.analytics'
import HotelsMap from '../HotelsMap/HotelsMap'

/**
 * Styles
 */
const COMPONENT_NAME = 'ListTabs'
loadStyles({ name: COMPONENT_NAME, folder: 'components/HotelsInfo' })

/**
 * Store
 */
const hotelsInfoStore = useHotelsInfoStore()

const { activeTab } = storeToRefs(hotelsInfoStore)

/**
 * Inject
 */
const i18n = inject('i18n')

/**
 * Data
 */
const tabs = [
  {
    key: 'list',
    text: i18n.value.tabs.list,
  },
  {
    key: 'map',
    text: i18n.value.tabs.map,
  },
]

/**
 * Methods
 */
function setActiveTab(tab) {
  hotelsInfoStore.setActiveTab(tab)

  emitUtagEvent({
    event_name: 'view_by',
    event_cat: 'destinations',
    event_act: 'view by',
    event_lbl: tab,
    event_purpose: 'inspiration',
    event_structure: 'body',
  })
}
</script>
