/**
 * Replaces keys in a text with values from a variable object.
 * @param {string} text The text containing keys to be replaced.
 * @param {Object} variable The object containing key-value pairs.
 * @returns {string} The text with keys replaced by values.
 */
export const replaceTranslationKeys = (text, variable) => {
  // Regular expression to find keys in the text.
  // %{} or {{}}
  const regex = /(%\{.*?\})|(\{\{.*?\}\})/g

  // Replace each key found with the value from the variable
  const replacedText = text.replace(regex, match => {
    // Extract the key name by removing the symbols
    const key = match.replace(/[{}%]/g, '')

    // Check if the key name is present in the object keys
    if (variable.hasOwnProperty(key)) {
      return variable[key]
    }
    return match // If key not found, return the original key
  })

  return replacedText
}
