import { defineStore } from 'pinia'

const initialState = {
  hotels: [],
  offers: [],
  filters: {
    offers: [],
    destinations: [],
    dates: [],
    categories: [],
    specialties: [],
    overview_features: [],
  },
  sort: {
    field: 'recommended',
    direction: 'desc',
  },
  pagination: {
    current_page: 1,
    items_per_page: 6,
    total_pages: 0,
  },
  activeTab: 'list',
}

function paginateHotels(state, hotels) {
  const startIndex = (state.pagination.current_page - 1) * state.pagination.items_per_page
  const endIndex = startIndex + state.pagination.items_per_page
  return hotels.slice(startIndex, endIndex)
}

function filterHotels(filters, hotels, exclusions = { offers: false, destinations: false, dates: false }) {
  let hotelsAux = hotels

  if (filters.offers.length && !exclusions.offers) {
    hotelsAux = hotelsAux.filter(hotel => {
      return filters.offers.some(offer => hotel.offers?.includes(offer.id))
    })
  }

  if (filters.destinations.length && !exclusions.destinations) {
    hotelsAux = hotelsAux.filter(hotel => {
      return filters.destinations.some(destination => destination.hotels.includes(hotel.id))
    })
  }

  if (filters.dates.length && !exclusions.dates) {
    hotelsAux = hotelsAux.filter(hotel => {
      return filters.dates.some(date => date.hotels.includes(hotel.id))
    })
  }

  if (filters.categories.length) {
    hotelsAux = hotelsAux.filter(hotel => {
      return filters.categories.every(category => hotel.hotel_category === category)
    })
  }

  if (filters.overview_features.length) {
    hotelsAux = hotelsAux.filter(hotel => {
      return filters.overview_features.every(overview_feature =>
        hotel.overview_features_ids.includes(overview_feature.id)
      )
    })
  }

  if (filters.specialties.length) {
    hotelsAux = hotelsAux.filter(hotel => {
      return filters.specialties.every(specialty => hotel.hotel_specialty_ids.includes(specialty.id))
    })
  }

  return hotelsAux
}

export const useHotelsInfoStore = defineStore('hotels_info', {
  state: () => {
    return JSON.parse(JSON.stringify(initialState))
  },
  actions: {
    setHotels(hotels) {
      this.hotels = hotels
      this.setPagination(hotels.length)
    },
    setOffers(offers) {
      this.offers = offers
    },
    setSort(field, direction) {
      this.sort.field = field
      this.sort.direction = direction
    },
    setPagination(total_items) {
      this.pagination.total_pages = Math.ceil(total_items / this.pagination.items_per_page)
      this.pagination.current_page = 1
    },
    setPage(page) {
      this.pagination.current_page = page
    },
    nextPage() {
      if (this.pagination.current_page * this.pagination.items_per_page < this.hotels.length) {
        this.pagination.current_page++
      }
    },
    prevPage() {
      if (this.pagination.current_page > 1) {
        this.pagination.current_page--
      }
    },
    replaceFilters(key, value) {
      this.filters[key] = value
    },
    updateFilters(key, value) {
      this.filters[key] = this.filters[key].concat(value)
    },
    removeFilters(key, value) {
      this.filters[key] = this.filters[key].filter(filter => filter.id !== value.id)
    },
    resetFilters() {
      this.filters = initialState.filters
    },
    setActiveTab(tab) {
      this.activeTab = tab
    },
  },
  getters: {
    hotelsFiltered: state => {
      let hotelAux = state.hotels
      const filters = state.filters

      hotelAux = filterHotels(filters, hotelAux)
      state.setPagination(hotelAux.length)
      return hotelAux
    },

    hotelsFilteredToOffers: state => {
      let hotelAux = state.hotels
      const filters = state.filters

      hotelAux = filterHotels(filters, hotelAux, { offers: true })
      return hotelAux
    },

    hotelsFilteredToDestinations: state => {
      let hotelAux = state.hotels
      const filters = state.filters

      hotelAux = filterHotels(filters, hotelAux, { destinations: true })
      return hotelAux
    },

    hotelsPaginated: state => {
      let hotelsAux = [...state.hotelsFiltered]

      switch (state.sort.field) {
        case 'recommended':
          // Return hotelsAux as is
          hotelsAux = state.hotelsFiltered
          break
        case 'price':
          hotelsAux.sort((a, b) => {
            const priceA = a.price_real?.price_value ? Number(a.price_real.price_value) : 0
            const priceB = b.price_real?.price_value ? Number(b.price_real.price_value) : 0
            if (state.sort.direction === 'asc') {
              return priceA - priceB
            }

            return priceB - priceA
          })
          break
        case 'name':
          hotelsAux.sort((a, b) => {
            if (state.sort.direction === 'asc') {
              return a.title.localeCompare(b.title)
            }

            return b.title.localeCompare(a.title)
          })
          break
      }

      hotelsAux.sort((a, b) => {
        if (a.price_real === null || a.price_real.price_value === null) {
          return 1
        }
        if (b.price_real === null || b.price_real.price_value === null) {
          return -1
        }

        return 0
      })

      return paginateHotels(state, hotelsAux)
    },
    highlightedOffer: state => {
      if (state.filters.offers.length !== 1) {
        return false
      }

      return state.offers.find(offer => offer.id === state.filters.offers[0].id)
    },
  },
})
