<template>
  <div v-if="hotels.length > pagination.items_per_page" class="list-pagination">
    <div class="list-pagination__info" v-html="paginationText"></div>
    <div class="list-pagination__navigation">
      <button
        class="list-pagination__arrow s s-arrow-left-fat"
        :disabled="pagination.current_page === 1"
        @click="hotelsInfoStore.prevPage"
      ></button>
      <div class="list-pagination__pages">
        <button
          v-for="index in pagination.total_pages"
          :key="index"
          class="list-pagination__page"
          :class="{
            'list-pagination__page--active': index === pagination.current_page,
          }"
          @click="hotelsInfoStore.setPage(index)"
        >
          {{ index }}
        </button>
      </div>
      <button
        class="list-pagination__arrow s s-arrow-right-fat"
        :disabled="pagination.current_page === pagination.total_pages"
        @click="hotelsInfoStore.nextPage"
      ></button>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { computed, inject } from 'vue'

import { replaceTranslationKeys as t } from '../../../../../utils/replaceTranslationKeys'
import { useHotelsInfoStore } from '../../../blocks/HotelsInfo/store/hotels_info'
import { loadStyles } from '../../../mixins/LoadStyles/LoadStyles'

/**
 * Styles
 */
const COMPONENT_NAME = 'ListPagination'
loadStyles({ name: COMPONENT_NAME, folder: 'components/HotelsInfo' })

/**
 * Inject
 */
const i18n = inject('i18n')
const resourceTitle = inject('resourceTitle')

/**
 * Store
 */
const hotelsInfoStore = useHotelsInfoStore()
const { pagination, hotelsFiltered: hotels } = storeToRefs(hotelsInfoStore)

/**
 * Computed
 */
const paginationText = computed(() => {
  const startIndex = (pagination.value.current_page - 1) * pagination.value.items_per_page + 1
  const endIndex = Math.min(startIndex + pagination.value.items_per_page - 1, hotels.value.length)
  return t(i18n.value.pagination.text, {
    start_index: startIndex,
    end_index: endIndex,
    total_hotels: hotels.value.length,
    resource: resourceTitle,
  })
})
</script>
