import currentEnv from '../../../src/core/utils/currentEnv.js'
import { initSegment, segmentUtils } from '../../utils/segmentation.js'

export const GMAPS_API_KEY = 'AIzaSyB2w3P_pk1J15KgRmcs5Vt_H6gfczdXmC0'
export const GMAPS_ID_MAP = 'ed672b1cd692b640'

const HOST_NAME = currentEnv.isProduction ? 'https://apiimg.iberostar.com' : 'https://pre-apiimg.iberostar.com'
let BASE_PATH = '/uploads/map/'
// This timestamp is to use it as parameter in the pins icon's and force the browser to load new assets
const PINS_TS = '202408060740'
initSegment()

if (IB.is_in_microsite && IB.current_microsite === 'ibercuba') {
  BASE_PATH = `/uploads/map/${IB.current_microsite}/`
}

if (segmentUtils.has() && !IB.is_in_microsite) {
  BASE_PATH = `/uploads/map/${segmentUtils.get()}/`
}

export const MARKERS = {
  defaultIcon: {
    icon: `${HOST_NAME + BASE_PATH}default_circle.svg?ts=${PINS_TS}`,
  },
  selectedtIcon: {
    icon: `${HOST_NAME + BASE_PATH}default_full_circle_selected.svg?ts=${PINS_TS}`,
  },
  cluster: {
    icon: `${HOST_NAME + BASE_PATH}cluster_with_border.svg?ts=${PINS_TS}`,
  },
  hotelIcon: {
    icon: `${HOST_NAME + BASE_PATH}hotel_star_mini.svg?ts=${PINS_TS}`,
  },
  hotelIconSelected: {
    icon: `${HOST_NAME + BASE_PATH}hotel_star_mini_selected.svg?ts=${PINS_TS}`,
  },
  hotelIconWhite: {
    icon: `${HOST_NAME + BASE_PATH}hotel_star_mini_white.svg?ts=${PINS_TS}`,
  },
  poiCat_1: {
    icon: `${HOST_NAME + BASE_PATH}hotel_star_mini.svg?ts=${PINS_TS}`,
  },
  poiCat_1_selected: {
    icon: `${HOST_NAME + BASE_PATH}hotel_star_mini_selected.svg?ts=${PINS_TS}`,
  },
  poiCat_2: {
    icon: `${HOST_NAME + BASE_PATH}dining.svg?ts=${PINS_TS}`,
  },
  poiCat_2_selected: {
    icon: `${HOST_NAME + BASE_PATH}dining_selected.svg?ts=${PINS_TS}`,
  },
  poiCat_3: {
    icon: `${HOST_NAME + BASE_PATH}tourist_attraction.svg?ts=${PINS_TS}`,
  },
  poiCat_3_selected: {
    icon: `${HOST_NAME + BASE_PATH}tourist_attraction_selected.svg?ts=${PINS_TS}`,
  },
  poiCat_4: {
    icon: `${HOST_NAME + BASE_PATH}shopping.svg?ts=${PINS_TS}`,
  },
  poiCat_4_selected: {
    icon: `${HOST_NAME + BASE_PATH}shopping_selected.svg?ts=${PINS_TS}`,
  },
  poiCat_5: {
    icon: `${HOST_NAME + BASE_PATH}nightlife.svg?ts=${PINS_TS}`,
  },
  poiCat_5_selected: {
    icon: `${HOST_NAME + BASE_PATH}nightlife_selected.svg?ts=${PINS_TS}`,
  },
  poiCat_6: {
    icon: `${HOST_NAME + BASE_PATH}information.svg?ts=${PINS_TS}`,
  },
  poiCat_6_selected: {
    icon: `${HOST_NAME + BASE_PATH}information_selected.svg?ts=${PINS_TS}`,
  },
  liveChat: {
    icon: `${HOST_NAME + BASE_PATH}live_chat.svg?ts=${PINS_TS}`,
  },
  liveChatSelcted: {
    icon: `${HOST_NAME + BASE_PATH}live_chat_selected.svg?ts=${PINS_TS}`,
  },
  border: {
    icon: `${HOST_NAME + BASE_PATH}border.svg?ts=${PINS_TS}`,
  },
  pinDefault: {
    icon: `${HOST_NAME + BASE_PATH}default.svg?ts=${PINS_TS}`,
  },
  iconIberostar: {
    icon: `${HOST_NAME + BASE_PATH}icono_ibe.svg?ts=${PINS_TS}`,
  },
  iconIberostarDefault: {
    icon: `${HOST_NAME + BASE_PATH}icono_ibe_default.svg?ts=${PINS_TS}`,
  },
  userLocation: {
    icon: `${HOST_NAME + BASE_PATH}user_location.svg?ts=${PINS_TS}`,
  },
}
